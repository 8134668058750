import {IDataRow} from './Components/IBLOCConfigs'
import {calculateIBLOCBalance} from './Components/IBLOCConfigs'
export const generateData = (dataArray: any[], defaultROI: number) => {
  let tempArray = []
  let givenRor = defaultROI / 100

  for (let i = 0; i < dataArray.length; i++) {
    let tempObj = {...dataArray[i]}

    // Set CashIn, APR, CashOut values
    tempObj['CashIn'] = dataArray[i].CashIn
    tempObj['APR'] = dataArray[i].APR
    tempObj['CashOut'] = dataArray[i].CashOut

    // Calculate IBLOCBalance
    tempObj['IBLOCBalance'] =
      i === 0
        ? (1 + tempObj.APR / 100) * tempObj.CashOut
        : calculateIBLOCBalance(
            tempObj.CashIn,
            tempObj.APR,
            tempObj.CashOut,
            tempArray[i - 1].IBLOCBalance
          )

    // Calculate IBLOCBalance for bar
    tempObj['IBLOCBalanceforBar'] = tempObj.IBLOCBalance * -1

    // Calculate Total Cash Value
    tempObj['TotalCashValueCalculated'] = tempObj.DividendTotalCashValue

    // Calculate Cash Balance
    tempObj['CashBalance'] =
      tempObj.TotalCashValueCalculated - tempObj.IBLOCBalance + tempObj.CashIn

    // Calculate Year-Over-Year Growth
    tempObj['CvGrowthYoY'] =
      i === 0
        ? 0
        : ((tempObj.TotalCashValueCalculated - tempArray[i - 1].TotalCashValueCalculated) /
            tempArray[i - 1].TotalCashValueCalculated) *
          100

    // Calculate LTD (Life-to-Date) Growth
    tempObj['TotalCvGrowthLTD'] =
      i === 0
        ? 0
        : ((tempObj.TotalCashValueCalculated - tempArray[0].TotalCashValueCalculated) /
            tempArray[0].TotalCashValueCalculated) *
          100

    // Calculate Cash Balance Growth
    tempObj['CashBalanceGrowth'] =
      i === 0
        ? 0
        : ((tempObj.CashBalance - tempArray[i - 1].CashBalance) / tempArray[i - 1].CashBalance) *
          100

    // Calculate LTD Cash Balance Growth
    tempObj['CashBalanceGrowthLTD'] =
      i === 0
        ? 0
        : ((tempObj.CashBalance - tempArray[0].CashBalance) / tempArray[0].CashBalance) * 100

    // Calculate Total Cash Put In So Far
    tempObj['TotalCashPutInSoFar'] =
      i === 0
        ? dataArray[i].TotalCashPutInSoFar
        : calculateTotalPut(i, dataArray) + dataArray[0].TotalCashPutInSoFar

    // Calculate Cash-On-Cash ROI
    tempObj['CashOnCashROI'] =
      i === 0
        ? 0
        : ((tempObj.CashBalance - tempObj.TotalCashPutInSoFar) / tempObj.TotalCashPutInSoFar) *
            100 || 0

    // Calculate Total Cash Opportunity
   tempObj['TotalCashOpportunity'] =
     i === 0
       ? tempObj.TotalCashPutInSoFar * Math.pow(1 + givenRor, 2) + tempObj.CashIn * (1 + givenRor)
       : (tempObj.CashIn + tempArray[i - 1].TotalCashOpportunity) * (1 + givenRor)

    // Calculate TCOO ROI
    tempObj['TCOOROI'] =
      i === 0
        ? 0
        : ((tempObj.TotalCashOpportunity - tempObj.TotalCashPutInSoFar) /
            tempObj.TotalCashPutInSoFar) *
          100

    // Calculate Deltas
    tempObj['TCVDelta'] = i === 0 ? 0 : tempObj.TotalCashValue - tempArray[i - 1].TotalCashValue
    tempObj['CBDelta'] = i === 0 ? 0 : tempObj.CashBalance - tempArray[i - 1].CashBalance

    // Calculate PL Interest as per the formula

    let cummulativeSumOfCashOutAndPLInterest =
      getTotalSumOfCashOut(i, tempArray, 'CashOut', tempObj.CashOut) +
      getTotalSumOfPLInterest(i, tempArray, 'PLInterest')
    tempObj['PLInterest'] =
      i === 0
        ? tempObj.IBLOCBalance - tempObj.CashOut
        : tempObj.IBLOCBalance - cummulativeSumOfCashOutAndPLInterest

    tempObj['IncreaseInCashBalance'] =
      i === 0 ? 0 : tempObj.CashBalance - tempArray[i - 1].CashBalance
    tempObj['cashInForComposedChart'] = tempObj.CashBalance + tempObj.CashIn
    tempObj['DividendIncreaseInTotalCashValueForComposedChart'] =
      tempObj.DividendIncreaseInTotalCashValue
    tempObj['alwaysPositivePLInterestForBars'] = Math.abs(tempObj.PLInterest)
    tempObj['negativeCashOut'] = -1 * tempObj.CashOut
    tempObj['CashBalanceBenchmark'] =
      Math.abs(tempObj.CashBalance) + Math.abs(tempObj.PLInterest) + Math.abs(tempObj.CashOut)

    tempObj['net'] =
      i === 0
        ? 0
        : tempObj.CashIn === 0
        ? tempObj.DividendIncreaseInTotalCashValue -
          (tempObj.IBLOCBalance - tempArray[i - 1].IBLOCBalance)
        : tempObj.DividendIncreaseInTotalCashValue - tempObj.CashIn
    tempArray.push(tempObj) // Push the modified object to the temp array
  }
  return tempArray
}

// Function to calculate the total cash put in so far
function calculateTotalPut(currentIndex: number, dataArray: any[]) {
  let sum = 0
  for (let i = 0; i <= currentIndex; i++) {
    sum = sum + dataArray[i].CashIn
  }
  return sum
}
function getTotalSumOfCashOut(
  currentIndex: number,
  dataArray: any[],
  keyToSum: string,
  currentCashOut: number
) {
  // Sum all the rows in tempArray that have already been processed (up to currentIndex)
  let sum = 0
  for (let i = 0; i < currentIndex; i++) {
    sum += dataArray[i][keyToSum]
  }
  return sum + currentCashOut // Add the current CashOut value as in your original code
}

// Function to calculate the total sum of PLInterest from the fixed starting row to the previous row
function getTotalSumOfPLInterest(currentIndex: number, dataArray: any[], keyToSum: string) {
  // Sum all the rows in tempArray that have already been processed (up to currentIndex-1)
  if (currentIndex === 0) return 0 // If the index is 0, there are no previous rows to sum
  let sum = 0
  for (let i = 0; i < currentIndex; i++) {
    sum += dataArray[i][keyToSum]
  }
  return sum
}
